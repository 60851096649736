import { template as template_8ce3ec464b1140228d4b37fc179f883b } from "@ember/template-compiler";
const FKControlMenuContainer = template_8ce3ec464b1140228d4b37fc179f883b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
