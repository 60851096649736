import { template as template_a6274f7243e944728e5083282cc4dbfe } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { htmlSafe } from "@ember/template";
export default class TopicLink extends Component {
    get url() {
        return this.args.topic.linked_post_number ? this.args.topic.urlForPostNumber(this.args.topic.linked_post_number) : this.args.topic.lastUnreadUrl;
    }
    static{
        template_a6274f7243e944728e5083282cc4dbfe(`
    {{~! no whitespace ~}}
    <a
      href={{this.url}}
      data-topic-id={{@topic.id}}
      role="heading"
      aria-level="2"
      class="title"
      ...attributes
    >{{htmlSafe @topic.fancyTitle}}</a>
    {{~! no whitespace ~}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
